<template>
  <div class="pageborder">
    <div class="pageback">
      <envir-page-name style="background-color: #ffffff;" :noBack="true" pageName="图片上传" />
      <div class="pageinpadding">
        <el-upload
          class="avatar-uploader"
          action="https://xinqingapi.deaso40.com/api/uploadpic/"
          :headers="getheader()"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        {{imageQiniuUrl}}
      </div>
    </div>
    <div class="pageback" v-loading="certLoading">
      <envir-page-name style="background-color: #ffffff;" :noBack="true" pageName="认证修改" />
      <div class="pageinpadding">
        <div style="margin-left: 16px;margin-bottom: 16px;">当前认证状态：{{ certShow ? '需要验证' : '不需要验证' }}，此认证仅限制使用计分功能</div>
        <el-button
          size="small"
          :type="certShow ? 'warning' : 'success'"
          style="margin-left: 16px;margin-bottom: 16px;"
          @click="changeCert()">{{ certShow ? '修改为不需要认证' : '修改为需要认证' }}</el-button>
      </div>
    </div>
    <div class="pageback">
      <envir-page-name style="background-color: #ffffff;" :noBack="true" pageName="公告修改" />
      <div class="pageinpadding">
        <div style="margin-left: 16px;margin-bottom: 16px;">当前公告图片地址：{{messageShow}}</div>
        <el-button
          size="small"
          type="success"
          style="margin-left: 16px;margin-bottom: 16px;"
          @click="messageDialog = true">修改公告图片地址</el-button>
      </div>
      <el-dialog title="修改公告" :visible.sync="messageDialog" v-loading="messageLoading">
        <div v-for="(user, index2) in userNameList" :key="index2">
          <el-input placeholder="请输入内容" v-model="messageValue">
            <template slot="prepend">新公告</template>
          </el-input>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="messageDialog = false">取 消</el-button>
          <el-button type="primary" @click="changeMessage()">修 改</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="pageback" v-loading="backupLoading">
      <envir-page-name style="background-color: #ffffff;" :noBack="true" pageName="数据备份" />
      <div class="pageinpadding">
        <div style="margin-left: 16px;">当前备份状态：{{ backupShow ? '备份中' : '已停止备份' }}</div>
        <div style="margin-left: 16px;">备份说明：备份一共保留五份：每5/15/25日、昨日、今日，均为早晨8点备份，新备份会将旧备份覆盖。备份存放位置为代码文件夹上一层的backup文件夹。</div>
        <div style="margin-left: 16px;margin-bottom: 16px;">若需要恢复数据，请立即停止备份并联系管理员。</div>
        <el-button
          size="small"
          :type="backupShow ? 'danger' : 'success'"
          style="margin-left: 16px;margin-bottom: 16px;"
          @click="changeBackup()">{{ backupShow ? '停止备份' : '开始备份' }}</el-button>
      </div>
    </div>
    <div class="pageback" v-loading="autoRankLoading">
      <envir-page-name style="background-color: #ffffff;" :noBack="true" pageName="排行自动更新" />
      <div class="pageinpadding">
        <div style="margin-left: 16px;margin-bottom: 16px;">当前自动更新状态：{{ autoRankShow ? '自动更新中' : '未自动更新' }}，更新时间为每天晚上23:59:59</div>
        <el-button
          size="small"
          :type="autoRankShow ? 'warning' : 'success'"
          style="margin-left: 16px;margin-bottom: 16px;"
          @click="changeAutoRank()">{{ autoRankShow ? '停止自动更新' : '开启自动更新' }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';
// action="http://localhost:7979/api/uploadpic/"
// action="https://xinqingapi.deaso40.com/api/uploadpic/"
export default {
  data() {
    return {
      imageUrl: '',
      imageQiniuUrl: '',
      messageShow: '',
      messageLoading: false,
      messageDialog: false,
      messageValue: '',
      certShow: '',
      certLoading: false,
      backupShow: '',
      backupLoading: false,
      autoRankShow: '',
      autoRankLoading: false
    };
  },
  mounted() {
    this.userNameList = [];
    this.userNameList.push({name: '新公告',label: 'value'});
    this.getMessage();
    this.getCert();
    this.getBackup();
    this.getAutoRank();
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageQiniuUrl = res.picurl;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    getheader(){
      return {
        'token': localStorage.getItem("xinqing_admin_userInfo")
      }
    },
    // 以下是公告部分
    async getMessage(){
      const { result } = await api.post('/getglobalkey', {
        key: 'message'
      });
      this.messageShow = result.value;
    },
    async changeMessage(){
      try{
        this.messageLoading = true;
        const { result } = await api.post('/changemessage', {
          value: this.messageValue
        });
        console.log(result);
        this.$message.success('修改公告成功');
        this.messageDialog = false;
        this.messageLoading = false;
        await this.getMessage();
      }catch(e){
        console.error(e);
        this.$message.error(e.message);
        this.messageLoading = false;
      }
    },
    // 以下是认证状态部分
    async getCert(){
      const { result } = await api.post('/getglobalkey', {
        key: 'needcert'
      });
      this.certShow = parseInt(result.value);
    },
    async changeCert(){
      const sure = await new Promise((resolve, reject) => {
        this.$msgbox({
          title: "提示",
          dangerouslyUseHTMLString: true,
          message: '确认要修改认证状态吗？',
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              done();
              resolve(true);
            } else {
              done();
              resolve(false);
            }
          }
        });
      });
      if (!sure) return;
      try{
        this.certLoading = true;
        const { result } = await api.post('/changeneedcert', {
          value: this.certShow ? '0' : '1'
        });
        console.log(result);
        this.$message.success('修改认证状态成功');
        this.certLoading = false;
        await this.getCert();
      }catch(e){
        console.error(e);
        this.$message.error(e.message);
        this.messageLoading = false;
      }
    },
    // 以下是备份状态部分
    async getBackup(){
      const { result } = await api.post('/getglobalkey', {
        key: 'backuprunning'
      });
      this.backupShow = parseInt(result.value);
    },
    async changeBackup(){
      const sure = await new Promise((resolve, reject) => {
        this.$msgbox({
          title: "提示",
          dangerouslyUseHTMLString: true,
          message: '确认要修改备份状态吗？',
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              done();
              resolve(true);
            } else {
              done();
              resolve(false);
            }
          }
        });
      });
      if (!sure) return;
      try{
        this.backupLoading = true;
        const { result } = await api.post('/changebackuprunning', {
          value: this.backupShow ? '0' : '1'
        });
        console.log(result);
        this.$message.success('修改备份状态成功');
        this.backupLoading = false;
        await this.getBackup();
      }catch(e){
        console.error(e);
        this.$message.error(e.message);
        this.messageLoading = false;
      }
    },
    // 以下是自动更新排名状态部分
    async getAutoRank(){
      const { result } = await api.post('/getglobalkey', {
        key: 'autorank'
      });
      this.autoRankShow = parseInt(result.value);
    },
    async changeAutoRank(){
      const sure = await new Promise((resolve, reject) => {
        this.$msgbox({
          title: "提示",
          dangerouslyUseHTMLString: true,
          message: '确认要修改自动更新排名吗？',
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              done();
              resolve(true);
            } else {
              done();
              resolve(false);
            }
          }
        });
      });
      if (!sure) return;
      try{
        this.autoRankLoading = true;
        const { result } = await api.post('/changeautorank', {
          value: this.autoRankShow ? '0' : '1'
        });
        console.log(result);
        this.$message.success('修改备份状态成功');
        this.autoRankLoading = false;
        await this.getAutoRank();
      }catch(e){
        console.error(e);
        this.$message.error(e.message);
        this.messageLoading = false;
      }
    },
  }
}
</script>

<style scoped lang="less">
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #5cbb7a;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
